#cv-file-upload{
    display: none;
}

#box{
    width: 15cm;
    position: absolute;
    right: 7cm;
    margin-top: -2cm;
}

#label-file-upload{
    height: 9cm;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-color: gray;
    background-color: #f8fafc;
}

#label-file-upload:hover{
    border-color: #1a202c;
    border-style: solid;
}

#label-file-upload{
    cursor: pointer;
}

.upload-button:hover{
    text-decoration-line: underline;
}
