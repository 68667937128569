#big{
    position: fixed;
    width: 400px;
    height: 200px;
    margin-top: 5cm;
}

#underline{
    border-radius: 0;
    margin-top: 6.4cm;
    width: 80cm;
    margin-right: -25px;
    margin-left: -50cm;
}

#vl{
    border-right: 1px solid white;
    height: 539px;
    margin-top: -14.27cm;
    margin-left: -90cm;
    margin-right: 11cm;
}

#cv-info-title{
    position: static;
    margin-left: 13cm;
    width: 5cm;
    font-size: 1.5rem;
}

#box{
    position: absolute;
    margin-right: -2cm;
}


#titleError{
    position: absolute;
    margin-left: 20.6cm;
    margin-top: -1.4cm;
    width: 5cm;
    left: -2cm;
}